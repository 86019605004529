import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ error }) => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-800">
      {error ? (
        <div className="text-center">
          <div className="text-6xl">❌</div>
          <p className="text-base text-gray-100 mt-5">
            Failed to retrieve data, refresh the page to try again.
          </p>
        </div>
      ) : (
        <div className="spinner"></div>
      )}
    </div>
  );
};

export default LoadingSpinner;
