import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const InteractiveBackground = () => {
  const meshRef = useRef();

  useFrame((state) => {
    const { clock, pointer } = state;
    meshRef.current.rotation.x = clock.getElapsedTime() / 2.5;
    meshRef.current.rotation.y = clock.getElapsedTime() / 2.5;
    meshRef.current.position.x = THREE.MathUtils.lerp(
      meshRef.current.position.x,
      (pointer.x * state.viewport.width) / 2,
      0.1
    );
    meshRef.current.position.y = THREE.MathUtils.lerp(
      meshRef.current.position.y,
      (pointer.y * state.viewport.height) / 2,
      0.1
    );
  });

  return (
    <mesh ref={meshRef}>
      <torusKnotGeometry args={[10, 3, 200, 20]} />
      <meshStandardMaterial color="#111827" wireframe />
    </mesh>
  );
};

export default InteractiveBackground;

/* Code below interacts with mouse click instead of mouse move

import React, { useRef, useEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const InteractiveBackground = () => {
  const meshRef = useRef();
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleClick = (event) => {
      const { clientX, clientY, target } = event;
      const { left, top, width, height } = target.getBoundingClientRect();
      const x = ((clientX - left) / width) * 2 - 1;
      const y = -((clientY - top) / height) * 2 + 1;

      setClickPosition({ x, y });
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  useFrame((state) => {
    const { clock } = state;
    meshRef.current.rotation.x = clock.getElapsedTime() / 2;
    meshRef.current.rotation.y = clock.getElapsedTime() / 2;
    meshRef.current.position.x = THREE.MathUtils.lerp(
      meshRef.current.position.x,
      (clickPosition.x * state.viewport.width) / 2,
      0.1
    );
    meshRef.current.position.y = THREE.MathUtils.lerp(
      meshRef.current.position.y,
      (clickPosition.y * state.viewport.height) / 2,
      0.1
    );
  });

  return (
    <mesh ref={meshRef}>
      <torusKnotGeometry args={[10, 3, 200, 20]} />
      <meshStandardMaterial color="teal" wireframe />
    </mesh>
  );
};

export default InteractiveBackground;
*/
