import React from "react";
import Banner from "../../components/Banner/Banner";
import ContactInfo from "./items/ContactInfo";
import ContactForm from "./items/ContactForm";
import { useData } from "../../services/DataProvider";

const Contact = () => {
  const { data } = useData();

  return (
    <main className="bg-gray-50 text-gray-900">
      {/* Hero Section */}
      <Banner
        gradient={"bg-gradient-to-r from-yellow-400 via-lime-400 to-green-400"}
        text1={data.contact.banner.title}
        text2={data.contact.banner.description}
      />

      {/* Contact Information Section */}
      <ContactInfo />

      {/* Contact Form Section */}
      <ContactForm />
    </main>
  );
};

export default Contact;
