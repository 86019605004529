import React from "react";
import { motion, useInView } from "framer-motion";
import SectionTitle from "../../../components/SectionTitle";
import { useData } from "../../../services/DataProvider";

const importImages = (r) => r.keys().map(r);

const imagesPartners = importImages(
  require.context(
    "/public/images/portfolio/partners",
    false,
    /\.(png|jpe?g|svg)$/
  )
).sort((a, b) => {
  const nameA = a.split("/").pop().toUpperCase();
  const nameB = b.split("/").pop().toUpperCase();
  return nameA.localeCompare(nameB);
});

const imagesClients = importImages(
  require.context(
    "/public/images/portfolio/clients",
    false,
    /\.(png|jpe?g|svg)$/
  )
).sort((a, b) => {
  const nameA = a.split("/").pop().toUpperCase();
  const nameB = b.split("/").pop().toUpperCase();
  return nameA.localeCompare(nameB);
});

const createImageAltText = (name) => {
  const baseName = name.split("/").pop().split(".")[0]; // Remove any URL parts if present
  return baseName
    .split(/[_]/)
    .map((word) => word.charAt(0) + word.slice(1))
    .join(" ");
};

const AboutUsSection2 = () => {
  const { data } = useData();

  const refPartners = React.useRef(null);
  const isPartnersInView = useInView(refPartners, { once: true });

  const refClients = React.useRef(null);
  const isClientsInView = useInView(refClients, { once: true });

  const refCompanyInfo = React.useRef(null);
  const isCompanyInfoInView = useInView(refCompanyInfo, { once: true });

  return (
    <section className="py-16 px-2">
      {/* Partners */}
      <SectionTitle
        title={data.about.content.section2.partners.title}
        description={data.about.content.section2.partners.description}
      />

      <div
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 px-4 pb-16"
        ref={refPartners}
      >
        {imagesPartners.map((image, index) => {
          const isBase64 = image.startsWith("data:image/");
          const imageAltText = isBase64
            ? `Image-${index}` // Use a generic name if base64
            : createImageAltText(image);

          return (
            <motion.div
              key={index}
              className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: isPartnersInView ? 1 : 0,
                y: isPartnersInView ? 0 : 20,
              }}
              transition={{ duration: 1, delay: index * 0.1 }}
            >
              <img
                src={image}
                alt={imageAltText}
                className="h-auto max-h-20 w-full object-contain mx-auto"
                loading="lazy"
              />
            </motion.div>
          );
        })}
      </div>

      {/* Clients */}
      <SectionTitle
        title={data.about.content.section2.clients.title}
        description={data.about.content.section2.clients.description}
      />

      <div
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 px-4 mb-10"
        ref={refClients}
      >
        {imagesClients.map((image, index) => {
          const isBase64 = image.startsWith("data:image/");
          const imageAltText = isBase64
            ? `Image-${index}` // Use a generic name if base64
            : createImageAltText(image);

          return (
            <motion.div
              key={index}
              className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: isClientsInView ? 1 : 0,
                y: isClientsInView ? 0 : 20,
              }}
              transition={{ duration: 1, delay: index * 0.1 }}
            >
              <img
                src={image}
                alt={imageAltText}
                className="h-auto max-h-20 w-full object-contain mx-auto"
                loading="lazy"
              />
            </motion.div>
          );
        })}
      </div>

      {/* Company Info */}
      <SectionTitle title={data.about.content.section2.companyInfo.title} />

      <div ref={refCompanyInfo}>
        <motion.p
          className="text-gray-500 text-base text-center md:text-lg lg:text-xl max-w-4xl mb-4 mx-auto"
          initial={{ opacity: 0 }}
          animate={{
            opacity: isCompanyInfoInView ? 1 : 0,
            y: isCompanyInfoInView ? 0 : 20,
          }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          {data.about.content.section2.companyInfo.fullName}
          {" / "}
          {data.about.content.section2.companyInfo.address}
          {" / "}
          {data.about.content.section2.companyInfo.court}
          {" / "}
          {data.about.content.section2.companyInfo.oib}
          {" / "}
          {data.about.content.section2.companyInfo.maticni}
          {" / "}
          {data.about.content.section2.companyInfo.equity}
          {" / "}
          {data.about.content.section2.companyInfo.bankAccounts.title}{" "}
          {data.about.content.section2.companyInfo.bankAccounts.bank1}
          {" • "}
          {data.about.content.section2.companyInfo.bankAccounts.bank2}
          {" / "}
          {data.about.content.section2.companyInfo.director}
        </motion.p>
      </div>
    </section>
  );
};

export default AboutUsSection2;
