import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useData } from "../services/DataProvider";

const CookiesConsent = () => {
  const [cookies, setCookie] = useCookies(["cookiesConsent"]);
  const [isVisible, setIsVisible] = useState(false);

  const { data } = useData();

  useEffect(() => {
    if (!cookies.cookiesConsent) {
      setIsVisible(true);
    }
  }, [cookies]);

  const handleAccept = () => {
    setCookie("cookiesConsent", "true", { path: "/", maxAge: 2592000 }); // 30 days
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 flex justify-center items-center z-50 opacity-90">
        <p>{data.cookiesConsent.text}</p>
        <button
          className="ml-4 px-4 py-2 bg-teal-500 hover:bg-teal-600 text-white font-semibold rounded-lg"
          onClick={handleAccept}
        >
          {data.cookiesConsent.button}
        </button>
      </div>
    )
  );
};

export default CookiesConsent;
