import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";
import Footer from "./components/Footer";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import ScrollToTop from "./services/ScrollToTop";
import CookiesConsent from "./components/CookiesConsent";
import { CookiesProvider } from "react-cookie";
import { useData } from "./services/DataProvider";

const App = () => {
  const { loading, error } = useData();

  if (loading || error) {
    return <LoadingSpinner error={error} />;
  }

  return (
    <CookiesProvider>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
      <CookiesConsent />
    </CookiesProvider>
  );
};

export default App;
