import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import InteractiveBackground from "./InteractiveBackground";

const Banner = ({
  gradient,
  text1,
  text2,
  buttonLink,
  buttonText,
  interactive,
}) => {
  return (
    <section
      className={`relative ${
        interactive ? "h-[90vh]" : "h-[70vh]"
      } ${gradient} text-white flex items-center justify-center overflow-hidden`}
    >
      {interactive && (
        <Canvas
          className="absolute inset-0 w-full h-full z-0 pointer-events-none"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <InteractiveBackground />
        </Canvas>
      )}
      <div className="relative z-10 text-center px-4 w-full flex flex-col items-center justify-center">
        <motion.h1
          className="text-5xl font-extrabold leading-tight mb-4"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {text1}
        </motion.h1>
        <motion.p
          className="text-lg md:text-xl mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          {text2}
        </motion.p>

        {buttonText ? (
          <Link to={`${buttonLink}`}>
            <motion.button
              className="px-6 py-3 bg-teal-500 hover:bg-teal-600 text-white font-semibold rounded-lg shadow-lg transition duration-300 transform hover:scale-105"
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ duration: 0.3 }}
            >
              {buttonText}
            </motion.button>
          </Link>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default Banner;
