import React from "react";
import { motion, useInView } from "framer-motion";

const GridItem = ({ color, title, description }) => {
  const gridItemRef = React.useRef(null);
  const gridItemInView = useInView(gridItemRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });

  return (
    <motion.div
      ref={gridItemRef}
      className={`p-6 ${color} rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: gridItemInView ? 1 : 0, y: gridItemInView ? 0 : 20 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      <h3
        className={`${
          description ? "text-2xl font-semibold mb-4" : "text-xl font-medium"
        }`}
      >
        {title}
      </h3>
      <p>{description}</p>
    </motion.div>
  );
};

export default GridItem;
