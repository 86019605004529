import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import GridItem from "../../../components/GridItem";
import { useData } from "../../../services/DataProvider";

const ServicesSection1 = () => {
  const { data } = useData();

  return (
    <section className="py-16 px-4 md:px-8 bg-white text-gray-800">
      <SectionTitle
        title={data.services.content.section1.title}
        description={data.services.content.section1.description}
      />

      <div className="grid md:grid-cols-3 gap-8">
        {data.services.content.section1.gridItems.map((item, index) => {
          return (
            <GridItem
              key={index}
              title={item.title}
              description={item.description}
              color={"bg-blue-300"}
            />
          );
        })}
      </div>
    </section>
  );
};

export default ServicesSection1;
