import React from "react";
import Banner from "../../components/Banner/Banner";
import ServicesSection1 from "./items/ServicesSection1";
import ServicesSection2 from "./items/ServicesSection2";
import ServicesSection3 from "./items/ServicesSection3";
import { useData } from "../../services/DataProvider";

const Services = () => {
  const { data } = useData();
  return (
    <main className="bg-gray-50 text-gray-900">
      {/* Hero Section */}
      <Banner
        gradient={"bg-gradient-to-r from-pink-500 via-red-400 to-orange-400"}
        text1={data.services.banner.title}
        text2={data.services.banner.description}
      />

      {/* SAP Section */}
      <ServicesSection1 />

      {/* Software Development Section */}
      <ServicesSection2 />

      {/* Call to Action Section */}
      <ServicesSection3 />
    </main>
  );
};

export default Services;
