import React from "react";
import Banner from "../../components/Banner/Banner";
import { useData } from "../../services/DataProvider";

const Home = () => {
  const { data } = useData();

  console.log(data);
  return (
    <main className="bg-gray-50 text-gray-900">
      {/* Hero Section */}
      <Banner
        // bg-gradient-to-r from-indigo-800 via-purple-600 to-pink-500
        // bg-gradient-to-b from-indigo-600 via-blue-400 to-teal-500
        gradient={"bg-gradient-to-b from-red-600 from-30% to-violet-800 to-65%"}
        text1={data.home.banner.title}
        text2={data.home.banner.description}
        buttonLink={`${data.home.banner.button.link}`}
        buttonText={`${data.home.banner.button.text}`}
        interactive={true}
      />
    </main>
  );
};

export default Home;
