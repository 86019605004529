import React from "react";
import { motion, useInView } from "framer-motion";

const SectionTitle = ({ title, description }) => {
  const titleRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const titleInView = useInView(titleRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });
  const descriptionInView = useInView(descriptionRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });

  return (
    <div className="text-center mb-8">
      <motion.h2
        ref={titleRef}
        className="text-4xl font-bold mb-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : 20 }}
        transition={{ duration: 0.5 }}
      >
        {title}
      </motion.h2>

      <motion.p
        ref={descriptionRef}
        className="text-lg mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{
          opacity: descriptionInView ? 1 : 0,
          y: descriptionInView ? 0 : 20,
        }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        {description}
      </motion.p>
    </div>
  );
};

export default SectionTitle;
